export default {
  name: 'taxDetails',
  components: {},
  props: ['billHdrId', 'rowDetails', 'billNature'],
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      taxDetailsData: [],
      taxDetailsFields: [
        {
          key: 'tax_group'
        },
        {
          key: 'tax_type'
        },
        {
          key: 'tax_master'
        },
        {
          key: 'tax_rate'
        },
        {
          key: 'exchange_rate'
        },
        {
          key: 'transaction_amount'
        },
        {
          key: 'taxable_amount'
        },
        {
          key: 'tax_amount'
        },
        {
          key: 'tp_reg_num',
          label: 'TP Reg No.'
        },
        {
          key: 'fp_reg_num',
          label: 'FP Reg No.'
        },
        {
          key: 'sac',
          label: 'SAC Code'
        },
        {
          key: 'hsn',
          label: 'HSN Code'
        }
      ],
      moduleId: this.$store.state.shared.moduleId
    };
  },
  mounted() {
    if (this.billNature === 'PROVISONAL') {
      this.getProvisionalTaxDetailsById();
    } else {
      this.getTaxDetailsById();
    }
  },
  methods: {
    getTaxDetailsById() {
      const payload = {
        bill_hdr_id: this.billHdrId,
        trx_line_id: this.rowDetails.bill_com_id,
        module_name:
          this.moduleId == 14
            ? 'FMS_BILL'
            : this.moduleId == 20
            ? 'LEASE_BILL'
            : ''
      };
      this.loader = true;
      this.$store
        .dispatch('fms/getTaxDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.taxDetailsData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getProvisionalTaxDetailsById() {
      const payload = {
        bill_hdr_id: this.billHdrId,
        trx_line_id: this.rowDetails.bill_com_id,
        module_name: 'FMS_BILL_TMP'
      };
      this.loader = true;
      this.$store
        .dispatch('fms/getProvisionalTaxDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.taxDetailsData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
