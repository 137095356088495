import TimesheetSummaryDetails from './timesheetSummaryDetails';
import commonHelper from '@/app/utility/common.helper.utility';
import Party from '@/app/components/generalLedger/party';
import TaxDetails from '../../../../shared/taxDetails';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'CreateNewBill',
  props: ['billDetails', 'billData'],
  components: {
    TimesheetSummaryDetails,
    TaxDetails,
    DatePicker,
    Party
  },
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      editMode: false,
      responseMsg: '',
      showValueSetModal: false,
      parent_value_set_id: null,
      module: { value: 14, text: 'FMS' },
      trxType: { value: null, text:null  },
      project: { value: null, text: null },
      billingProject: { value: null, text: null },
      legalEntity: { value: null, text: null },
      operatingUnit: { value: null, text: null },
      withUnit: { value: null, text: null },
      unit: { value: null, text: null },
      subUnit: { value: null, text: null },
      customer: { id: null, text: null },
      customerSite: { id: null, text: null },
      billStatus: { value: null, text: null },
      source: { value: null, text: null },
      currency: { value: null, text: null },
      billCat: { value: null, text: null },
      payTerm: { value: null, text: null },
      billCycle: { value: null, text: null },
      defaultValue: { value: null, text: null },
      billNumber: null,
      amount: null,
      taxAmount: null,
      totalAmount: null,
      billPeriodFrom: null,
      billPeriodTo: null,
      glDate: null,
      billDate: null,
      dueDate: null,
      valueDate: null,
      address: null,
      fpGstn: null,
      tpGstn: null,
      billCreated: false,
      billNature: null,
      version: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      updationDate: null,
      mergedTrx: null,
      holdUnhold: null,
      salesAgmt: { id: null, text: null },
      createBillData: [
        {
          bill_com_id: 0,
          sales_agmt_consumption_dtl_id: 0,
          component_group_vset_meaning: null,
          component_group_vset: null,
          component_name_vset_meaning: null,
          component_name_vset: null,
          description: null,
          uom_vset: null,
          uom_vset_meaning: null,
          quantity: null,
          rate: null,
          actual_rate: null,
          amount: null,
          total_amount: null,
          tax_cat_vset: null,
          tax_cat_vset_meaning: null,
          tax_amount: null,
          sac_code: null,
          hsn_code: null,
          revenue_account: null,
          revenue_account_meaning: null,
          billing_type_vset: null,
          overtime_uom: null,
          overtime_rate: null,
          overtime_amount: null,
          overtime_hours: null,
          overtime_days: null
        }
      ],
      createBillFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'component_group_vset_meaning',
          label: 'Component Group'
        },
        {
          key: 'component_name_vset_meaning',
          label: 'Component Name'
        },
        {
          key: 'description'
        },
        {
          key: 'quantity'
        },
        {
          key: 'uom_vset_meaning',
          label: 'UOM'
        },
        {
          key: 'rate',
          label: 'Component Rate'
        },
        {
          key: 'actual_rate'
        },
        {
          key: 'overtime_amount',
          label: 'OT Amount',
          class: 'text-center'
        },
        {
          key: 'amount'
        },
        {
          key: 'tax_cat_vset_meaning',
          label: 'Tax Category'
        },
        {
          key: 'sac_code',
          label: 'SAC Code'
        },
        {
          key: 'hsn_code',
          label: 'HSN Code'
        },
        {
          key: 'revenue_account_meaning',
          label: 'Revenue Account'
        },
        {
          key: 'overtime_uom',
          label: 'OT UOM',
          class: 'text-center'
        },
        {
          key: 'overtime_hours',
          label: 'OT Hours',
          class: 'text-center'
        },
        {
          key: 'overtime_days',
          label: 'OT Days',
          class: 'text-center'
        },
        {
          key: 'overtime_rate',
          label: 'OT Rate',
          class: 'text-center'
        },
        {
          key: 'tax_details'
        },
        {
          key: 'consumption_details'
        }
      ],
      showPartyModal: false,
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      specIndex: null,
      addressId: null,
      showTaxDetailsModal: false,
      rowDetails: null,
      billHdrId: 0,
      moduleId: null,
      gstn: null,
      isGstnAvailable: null,
      showTimesheetDetailsModal: false,
      timesheetSummaryDetailModal: false,
      downloadPayload: null,
      selectedTimesheetDetails: null,
      salesAgmtConsumptionDtlData: [],
      salesAgmtConsumptionDtlField: [
        {
          key: 'component_name'
        },
        {
          key: 'comp_meaning'
        },
        {
          key: 'rate'
        },
        {
          key: 'quantity'
        },
        {
          key: 'hsn_code'
        },
        {
          key: 'tax_cat_name'
        },
        {
          key: 'sac_code'
        }
      ],
      showSalesConsumptionDetailModal: false,
      timesheetType: null,
      billFlag: 'FINAL',
      sharingRatio: 100,
      receiptMethodName: {
        value: null,
        text: null
      },
      ar_prj_receipt_method_mpng_id: null,
      showCreateBillModal: false,
      meterNo:null
    };
  },
  validations: {
    module: {
      value: { required }
    },
    trxType: {
      value: { required }
    },
    project: {
      value: { required }
    },
    billingProject: {
      value: { required }
    },
    legalEntity: {
      value: { required }
    },
    operatingUnit: {
      value: { required }
    },
    customer: {
      id: { required }
    },
    customerSite: {
      id: { required }
    },
    currency: {
      value: { required }
    },
    glDate: { required },
    billDate: { required }
  },
  mounted() {
    if (this.billDetails) {
      this.billHdrId = this.billDetails.bill_hdr_id;
      this.billFlag = this.billDetails.bill_nature;
      this.billCreated = true;
      this.createBillFields.push({
        key: 'timesheet_details'
      });
      this.billCreated = true;
      this.receiptMethodName = {
        value: this.billDetails.ar_prj_receipt_method_mpng_id,
        text: this.billDetails.ar_prj_receipt_method_mpng_name
      };
      this.meterNo =this.billDetails.meter_no
      this.getBillDetailsById(this.billHdrId);
    } else if (this.billData) {
      this.billHdrId = this.billData.bill_hdr_id;
      this.billFlag = this.billData.bill_type === 'F' ? 'FINAL' : 'PROVISIONAL';
      this.billCreated = true;
      this.createBillFields.push({
        key: 'timesheet_details'
      });
      this.getBillDetailsById(this.billHdrId);
    } else {
      this.billFlag = 'FINAL';
      this.editMode = true;
      this.setDefaultCurrency();
      this.setDefaultSource();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          if (this.billNature === 'PROVISONAL') {
            this.saveActualRateForProvisional();
          } else if (this.billNature === 'FINAL') {
            this.editFinalBillDate();
          } else {
            this.calculateTotalAmount();
          }
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'delete') {
          this.deleteTempBillFinalBill();
        }
      }
    });
  },
  methods: {
    getBillDetailsById(bill_hdr_id) {
      const payload = {
        bill_hdr_id: bill_hdr_id,
        queryParams: {
          flag: this.billFlag
        }
      };
      this.loader = true;
      this.$store
        .dispatch('fms/getFmsBillDetailsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.setDataForBillDetails(result);
          }
        });
    },
    updateBillHoldUnhold() {
      const payload = {
        is_hold: [
          {
            bill_id: this.billHdrId,
            bill_nature: this.billNature,
            is_hold_flag: this.holdUnhold
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('fmsTransaction/updateBillHoldUnhold', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    setDataForBillDetails(item) {
      this.salesAgmt.text = item.sales_agreement_num;
      this.salesAgmt.id = item.sales_agreement_hdr_id;
      this.version = item.sales_agreement_version;
      this.createdBy = item.created_by;
      this.creationDate = item.creation_date;
      this.lastUpdatedBy = item.last_updated_by;
      this.updationDate = item.updation_date;
      this.mergedTrx = item.is_merged_trx;
      this.holdUnhold = item.is_hold_flag;
      this.billNature = item.bill_nature;
      this.trxType.text = item.trx_type;
      this.trxType.value = item.trx_type_dtl_id;
      this.module.text = item.module_name;
      this.module.value = item.module_id;
      this.project.text = item.project_name;
      this.project.value = item.project_id;
      this.billingProject.text = item.billing_prj_name;
      this.billingProject.value = item.billing_prj_id;
      this.legalEntity.text = item.le_name;
      this.legalEntity.value = item.le_id;
      this.operatingUnit.text = item.ou_name;
      this.operatingUnit.value = item.ou_id;
      this.withUnit.text = item.bill_type_meaning;
      this.withUnit.value = item.bill_type_vset;
      this.unit.text = item.unit_name;
      this.unit.value = item.unit_id;
      this.subUnit.text = item.fms_sub_unit_name;
      this.subUnit.value = item.fms_sub_unit_id;
      this.customer.name = item.customer_name;
      this.customer.id = item.customer_id;
      this.customerSite.name = item.customer_site_name.toUpperCase();
      this.customerSite.id = item.customer_site_id;
      this.address = item.customer_site_address;
      this.billStatus.text = item.bill_status_meaning;
      this.billStatus.value = item.bill_status_vset;
      this.source.text = item.source_name.toUpperCase();
      this.source.value = item.source_hdr_id;
      this.payTerm.text = item.payment_term_name;
      this.payTerm.value = item.payment_term_id;
      this.currency.text = item.currency;
      this.currency.value = item.currency_id;
      this.billCat.text = item.bill_cat_vset_meaning;
      this.billCat.value = item.bill_cat_vset;
      this.amount = item.amount ? commonHelper.formatAmount(item.amount) : null;
      this.totalAmount = item.total_amount
        ? commonHelper.formatAmount(item.total_amount)
        : null;
      this.taxAmount = item.tax_amount
        ? commonHelper.formatAmount(item.tax_amount)
        : null;
      this.billPeriodFrom = item.period_start_date;
      this.billPeriodTo = item.period_end_date;
      this.glDate = item.gl_date;
      this.billDate = item.doc_gen_date;
      this.valueDate = item.value_date;
      this.dueDate = item.due_date;
      this.billNumber = item.document_num;
      this.fpGstn = item.first_party_gstn;
      this.tpGstn = item.third_party_gstn;
      this.billHdrId = item.bill_hdr_id;
      this.timesheetType = item.timesheet_type;
      this.sharingRatio = item.unit_area_sharing_ratio;
      this.setOTDetails(item);
    },
    getOvertimeDetailsById() {
      const payload = {
        bill_hdr_id: this.billHdrId,
        queryParams: {
          flag: this.billFlag
        }
      };
      const timesheetType =
        this.timesheetType == 'SUMMARY_TIMESHEET'
          ? 'getOvertimeSummaryDetailsById'
          : 'getOvertimeDetailsById';
      this.loader = true;
      this.$store.dispatch(`fms/${timesheetType}`, payload).then(response => {
        this.loader = false;
        if (response.status === 200) {
          const result = {
            bill_components: response.data.data
          };
          this.setOTDetails(result);
        }
      });
    },
    setOTDetails(item) {
      this.createBillData = item.bill_components.map(data => {
        return {
          sales_agmt_consumption_dtl_id: data.sales_agmt_consumption_dtl_id,
          component_group_vset: data.fms_comp_group_id,
          component_group_vset_meaning: data.fms_comp_group_name,
          component_name_vset: data.fms_comp_id,
          component_name_vset_meaning: data.fms_comp_display_name,
          description: data.description,
          uom_vset: data.area_uom,
          uom_vset_meaning: data.area_uom,
          rate: data.rate,
          actual_rate: data.actual_rate,
          amount: commonHelper.formatAmount(data.amount),
          tax_cat_vset: data.tax_cat_hdr_id,
          tax_cat_vset_meaning: data.tax_cat_name,
          sac_code: data.sac_code,
          hsn_code: data.hsn_code,
          revenue_account: data.revenue_account,
          revenue_account_meaning: data.revenue_account_meaning,
          tax_amount: data.transaction_amt,
          quantity: data.area,
          bill_com_id: data.bill_com_id,
          sales_agreement_comp_dtl_id: data.sales_agreement_comp_dtl_id,
          billing_type_vset: data.billing_type_vset,
          overtime_uom: data.overtime_uom,
          overtime_rate: data.overtime_rate,
          overtime_amount: data.overtime_amount,
          overtime_hours: data.overtime_hours,
          overtime_days: data.overtime_days,
          timesheet_summary: data.timesheet_summary,
          timesheet_summary_hdr_id: data.timesheet_summary_hdr_id
        };
      });
      this.createBillData.map(data => {
        data.tax_details = 'View Details';
        return data;
      });
    },
    addEditFmsBill() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const billComp = this.createBillData.map(elem => {
          return {
            amount: parseFloat(elem.amount),
            area: elem.quantity,
            area_uom: elem.uom_vset,
            bill_com_id: elem.bill_com_id,
            fms_comp_display_name: elem.component_name_vset_meaning,
            fms_comp_group_id: elem.component_group_vset,
            fms_comp_id: elem.component_name_vset,
            rate: parseFloat(elem.rate),
            revenue_account: elem.revenue_account,
            sac_code: elem.sac_code,
            hsn_code: elem.hsn_code,
            tax_cat_hdr_id: elem.tax_cat_vset
          };
        });
        const payload = {
          bill_hdr_id: this.billHdrId,
          trx_type_dtl_id: this.trxType.value,
          module_id: this.module.value,
          project_id: this.project.value,
          billing_prj_id: this.billingProject.value,
          le_id: this.legalEntity.value,
          ou_id: this.operatingUnit.value,
          bill_type_vset: this.withUnit.value,
          unit_id: this.unit.value,
          fms_sub_unit_id: this.subUnit.value ? this.subUnit.value : null,
          customer_id: this.customer.id,
          customer_site_id: this.customerSite.id,
          source_hdr_id: this.source.value,
          payment_term_id: this.payTerm.value,
          currency_id: this.currency.value,
          bill_cat_vset: this.billCat.value,
          base_amount: parseFloat(this.amount),
          period_start_date: this.billPeriodFrom,
          period_end_date: this.billPeriodTo,
          gl_date: this.glDate,
          doc_gen_date: this.billDate,
          value_date: this.valueDate,
          unit_area_sharing_ratio: this.sharingRatio ? this.sharingRatio : 100,
          bill_components: billComp ? billComp : null,
          ar_prj_receipt_method_mpng_id: this.receiptMethodName.value,
          meter_no:this.meterNo
        };
        this.loader = true;
        this.$store
          .dispatch('fms/addEditBillDetails', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.billHdrId = response.data.data.bill_hdr_id;
              this.moduleId = response.data.data.module_id;
              this.billCreated = true;
              this.editMode = false;
              this.isSuccess = true;
              this.showAlert = true;
              this.responseMsg = response.data.message;
              this.getBillDetailsById(this.billHdrId);
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    editFinalBillDate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          bill_hdr_id: this.billHdrId,
          doc_gen_date: this.billDate,
          gl_date: this.glDate,
          ar_prj_receipt_method_mpng_id: this.receiptMethodName.value,
          
        };
        this.loader = true;
        this.$store
          .dispatch('fms/editFinalBillDate', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.showAlert = true;
              this.responseMsg = response.data.message;
              this.getBillDetailsById(this.billHdrId);
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    saveActualRateForProvisional() {
      const billComp = [];
      this.createBillData.map(elem => {
        billComp.push({
          tmp_bill_hdr_id: this.billHdrId,
          tmp_bill_comp_id: elem.bill_com_id,
          sales_agmt_comp_dtl_id: elem.sales_agreement_comp_dtl_id,
          rate: elem.rate,
          actual_rate: elem.actual_rate
        });
      });
      const payload = {
        tempbillPayload: {
          tempbill: billComp,
        },
        methodId: {
          arPrjReceiptMethodMappingId: this.receiptMethodName.value
        }
      };

      this.loader = true;
      this.$store
        .dispatch('fms/saveActualRateForProvisional', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.showAlert = true;
            this.responseMsg = response.data.message;
            this.getBillDetailsById(this.billHdrId);
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHidePartyModal(flag) {
      this.showPartyModal = flag;
      if (this.withUnit.value) {
        this.setPartyTimeout = setTimeout(() => {
          this.eventBus.$emit('userType', 'CUST');
        }, 0);
      } else {
        alert('Please select party type!');
      }
      // if (this.partyType) {
      //   this.showPartyModal = flag;
      //   if (flag) {
      //     if (this.partyType === 'VENDOR') {
      //       this.setPartyTimeout = setTimeout(() => {
      //         this.eventBus.$emit('userType', 'VEND');
      //       }, 0);
      //     } else if (this.partyType === 'CUSTOMER') {
      //       this.setPartyTimeout = setTimeout(() => {
      //         this.eventBus.$emit('userType', 'CUST');
      //       }, 0);
      //     }
      //   }
      // } else {
      //   alert('Please select party type!');
      // }
    },
    getSelectedPartyCustomer(item) {
      this.customer.name = item.party_name;
      this.customer.id = item.party_id;
      this.customerSite.name = null;
      this.customerSite.id = null;
      this.address = null;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.customer.id = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.customer.name) {
        this.showCustomerSiteModal = flag;
      } else {
        alert('Please Select Customer First');
      }
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customer_id: this.customer.id,
        ou_id: this.operatingUnit.value
      };
      this.gridDataSite = [];
      this.$store
        .dispatch('party/getCustomerSiteByOu', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.customerSite.name = item.site_name;
      this.customerSite.id = item.customer_site_id;
      this.address = item.bill_to_address;
      this.addressId = item.bill_to_address_id;
      this.gstn = item.gstn;
      this.isGstnAvailable =
        this.gstn !== 'GSTNOTAVAILABLE' && this.gstn !== null ? true : false;
      this.showCustomerSiteModal = false;
    },
    checkSharingRatio() {
      if (this.sharingRatio > 100) {
        alert('Sharing can not be greater than 100');
        this.sharingRatio = null;
      }
    },
    getSharingRatio() {
      const payload = {
        customer_id: this.customer.id,
        unit_id: this.unit.value
      };
      this.loader = true;
      this.$store
        .dispatch('fms/getSharingRatio', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.sharingRatio = response.data.data.sharing_ratio;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openModuleBasedProject(moduleName) {
      if (moduleName) {
        if (moduleName === 'FMS') {
          this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
        } else if (moduleName === 'Lease Management') {
          this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
        } else {
          alert('Please Select FMS or Lease Module');
        }
      } else {
        alert('Please Select Module');
      }
    },
    openModuleBasedUnit(moduleName) {
      if (moduleName) {
        if (moduleName === 'FMS') {
          this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_UNIT);
        } else if (moduleName === 'Lease Management') {
          this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_UNIT_LIST);
        } else {
          alert('Please Select FMS or Lease Module');
        }
      } else {
        alert('Please Select Module');
      }
    },
    openCustomerBasedOnModule(moduleName, basedOn) {
      if (moduleName === 'FMS') {
        if (basedOn === 'SUBUNIT') {
          if (this.subUnit.value) {
            this.openValueSetModal('FMS_CUSTOMER_BY_SUBUNIT');
          } else {
            alert('Please Select Sub Unit');
          }
        } else if (basedOn === 'WSUBUNIT') {
          this.showHidePartyModal(true);
        } else {
          alert('Please Select With/Without Type');
        }
      } else if (moduleName === 'Lease Management') {
        if (basedOn === 'SUBUNIT') {
          if (this.unit.value) {
            this.openValueSetModal('LEASE_CUSTOMER_BY_UNIT');
          } else {
            alert('Please Select Unit');
          }
        } else if (basedOn === 'WSUBUNIT') {
          this.openValueSetModal('LEASE_CUSTOMER_BY_PRJ');
        } else {
          alert('Please Select With/Without Type');
        }
      } else {
        alert('Please Select Module');
      }
    },
    openTaxCatBasedOnProject(moduleName, index) {
      if (moduleName === 'FMS') {
        this.openValueSetModal('TAX_CATEGORY_NAME_VSET', index);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal('LEASE_TAX_CAT', index);
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_COMPONENT) {
        this.parent_value_set_id = this.createBillData[
          this.specIndex
        ].component_group_vset;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.parent_value_set_id = this.unit.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_CUSTOMER_BY_SUBUNIT
      ) {
        this.parent_value_set_id = this.subUnit.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_CUSTOMER_BY_PROJ
      ) {
        this.parent_value_set_id = this.project.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_CUSTOMER_BY_UNIT
      ) {
        this.parent_value_set_id = this.unit.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_CUSTOMER_BY_PRJ
      ) {
        this.parent_value_set_id = this.project.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TAX_CAT) {
        this.parent_value_set_id = this.project.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.parent_value_set_id = 1;
      }
      else if ( this.vsetCode === appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET){
        this.parent_value_set_id = this.billingProject.value
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.project = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.billingProject = {
          text: item.fms_prj_name,
          value: item.fms_prj_id
        };
        this.legalEntity = { text: item.fms_le_name, value: item.fms_le_id };
        this.operatingUnit = { text: item.fms_ou_name, value: item.fms_ou_id };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.project = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.billingProject = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.legalEntity = { text: item.le_name, value: item.org_le_id };
        this.operatingUnit = { text: item.ou_name, value: item.org_ou_id };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_SEARCH_BY) {
        this.withUnit = {
          text: item.value_meaning,
          value: item.value_code
        };
        this.unit = this.defaultValue;
        this.customer.name = null;
        this.customer.id = null;
        this.customerSite.name = null;
        this.customerSite.id = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.unit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_UNIT) {
        this.unit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.subUnit = { value: null, text: null };
        if (this.unit.value && this.customer.id) {
          this.getSharingRatio();
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.subUnit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PAYMENT_TERM) {
        this.payTerm = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CURRENCY_LIST) {
        this.currency.text = item.value_code;
        this.currency.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_CAT_VSET) {
        this.billCat.text = item.value_meaning;
        this.billCat.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.billCycle = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.COMPONENT_GRP) {
        this.createBillData[this.specIndex].component_group_vset_meaning =
          item.value_code;
        this.createBillData[this.specIndex].component_group_vset =
          item.value_set_dtl_id;
        this.createBillData[this.specIndex].component_name_vset_meaning = null;
        this.createBillData[this.specIndex].component_name_vset = null;
        this.createBillData[this.specIndex].uom_vset = null;
        this.createBillData[this.specIndex].uom_vset_meaning = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_COMPONENT) {
        this.createBillData[this.specIndex].component_name_vset_meaning =
          item.value_code;
        this.createBillData[this.specIndex].component_name_vset =
          item.value_set_dtl_id;
        this.createBillData[this.specIndex].uom_vset = item.uom;
        this.createBillData[this.specIndex].uom_vset_meaning = item.uom_meaning;
        this.createBillData[this.specIndex].sac_code = item.sac;
        this.createBillData[this.specIndex].hsn_code = item.hsn;
        this.createBillData[this.specIndex].revenue_account_meaning =
          item.fms_comp_revenue_ac_meaning;
        this.createBillData[this.specIndex].revenue_account =
          item.fms_comp_revenue_ac;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.createBillData[this.specIndex].tax_cat_vset_meaning =
          item.value_code;
        this.createBillData[this.specIndex].tax_cat_vset =
          item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TAX_CAT) {
        this.createBillData[this.specIndex].tax_cat_vset_meaning =
          item.value_code;
        this.createBillData[this.specIndex].tax_cat_vset =
          item.value_set_dtl_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_CUSTOMER_BY_SUBUNIT ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_CUSTOMER_BY_PROJ
      ) {
        this.customer.name = item.value_code;
        this.customer.id = item.value_set_dtl_id;
        this.getCustomerSiteByPartyId();
        if (this.customer.id && this.unit.value) {
          this.getSharingRatio();
        }
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_CUSTOMER_BY_UNIT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_CUSTOMER_BY_PRJ
      ) {
        this.customer.name = item.value_code;
        this.customer.id = item.value_set_dtl_id;
        this.customerSite.name = null;
        this.customerSite.id = null;
        this.address = null;
        this.addressId = null;
        this.getCustomerSiteByPartyId();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.VATIKA_GL_Accounts) {
        this.createBillData[this.specIndex].revenue_account = item.value_code;
        this.createBillData[this.specIndex].revenue_account_meaning =
          item.value_meaning;
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET
      ) {
        this.receiptMethodName = {
          value: item.ar_proj_receipt_method_mpng_id,
          text: item.receipt_methods_name
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TRX_TYPE_VSET){
        this.trxType ={
          value: item.trx_type_dtl_id,
          text:item.trx_desc
        }

      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.project.value) {
        this.project = this.defaultValue;
        this.billingProject = this.defaultValue;
        this.legalEntity = this.defaultValue;
        this.operatingUnit = this.defaultValue;
        this.withUnit = this.defaultValue;
        this.unit = this.defaultValue;
        this.subUnit = this.defaultValue;
        this.customer.name = null;
        this.customer.id = null;
        this.customerSite.name = null;
        this.customerSite.id = null;
        this.address = null;
      } else if (vsetCode === this.withUnit.value) {
        this.withUnit = this.defaultValue;
        this.unit = this.defaultValue;
        this.subUnit = this.defaultValue;
        this.customer.name = null;
        this.customer.id = null;
        this.customerSite.name = null;
        this.customerSite.id = null;
        this.address = null;
      } else if (vsetCode === this.unit.value) {
        this.unit = this.defaultValue;
        this.subUnit = this.defaultValue;
        this.customer.name = null;
        this.customer.id = null;
        this.customerSite.name = null;
        this.customerSite.id = null;
        this.address = null;
      } else if (vsetCode === this.subUnit.value) {
        this.subUnit = this.defaultValue;
        this.customer.name = null;
        this.customer.id = null;
        this.customerSite.name = null;
        this.customerSite.id = null;
        this.address = null;
      } else if (vsetCode === this.customer.id) {
        this.customer.name = null;
        this.customer.id = null;
        this.customerSite.name = null;
        this.customerSite.id = null;
        this.address = null;
      } else if (vsetCode === this.customerSite.id) {
        this.customerSite.name = null;
        this.customerSite.id = null;
        this.address = null;
      } else if (vsetCode === this.payTerm.value) {
        this.payTerm = this.defaultValue;
      } else if (vsetCode === this.currency.value) {
        this.currency = this.defaultValue;
      } else if (vsetCode === this.billCat.value) {
        this.billCat = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET){
        this.receiptMethodName = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.TRX_TYPE_VSET){
        this.trxType =this.defaultValue;
      }
    },
    clearVsetValuesOnGrid(vsetCode, index) {
      if (vsetCode === appStrings.VALUESETTYPE.COMPONENT_GRP) {
        this.createBillData[index].component_group_vset = null;
        this.createBillData[index].component_group_vset_meaning = null;
        this.createBillData[index].component_name_vset = null;
        this.createBillData[index].component_name_vset_meaning = null;
        this.createBillData[index].uom_vset = null;
        this.createBillData[index].uom_vset_meaning = null;
        this.createBillData[index].sac_code = null;
        this.createBillData[index].hsn_code = null;
        this.createBillData[index].revenue_account = null;
        this.createBillData[index].revenue_account_meaning = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.FMS_COMPONENT) {
        this.createBillData[index].component_name_vset = null;
        this.createBillData[index].component_name_vset_meaning = null;
        this.createBillData[index].uom_vset = null;
        this.createBillData[index].uom_vset_meaning = null;
        this.createBillData[index].sac_code = null;
        this.createBillData[index].hsn_code = null;
        this.createBillData[index].revenue_account = null;
        this.createBillData[index].revenue_account_meaning = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET) {
        this.createBillData[index].tax_cat_vset = null;
        this.createBillData[index].tax_cat_vset_meaning = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.VATIKA_GL_Accounts) {
        this.createBillData[index].revenue_account = null;
        this.createBillData[index].revenue_account_meaning = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    calculateAmountForComponent(rowDetail) {
      if (this.trxType.text === 'Invoice'){
        const totalAmount = rowDetail.quantity * rowDetail.rate;
      return (rowDetail.amount = parseFloat(totalAmount).toFixed(2));

      } else {
        const totalAmount = rowDetail.quantity * rowDetail.rate;
        const newTotalAmount = - totalAmount
      return (rowDetail.amount = parseFloat(newTotalAmount).toFixed(2));


      }
      
    },
    addNewRow() {
      if (this.editMode) {
        this.createBillData.push({
          bill_com_id: 0,
          component_group_vset_meaning: null,
          component_group_vset: null,
          component_name_vset_meaning: null,
          component_name_vset: null,
          uom_vset: null,
          uom_vset_meaning: null,
          quantity: null,
          rate: null,
          actual_rate: null,
          amount: null,
          tax_cat_vset: null,
          tax_cat_vset_meaning: null,
          tax_amount: null,
          sac_code: null,
          hsn_code: null,
          revenue_account: null,
          revenue_account_meaning: null,
          billing_type_vset: null
        });
      }
    },
    removeRow(index) {
      this.createBillData.splice(index, 1);
    },
    showHideTimesheetDetailsModal(flag, item) {
      if (flag) {
        this.timesheetSummaryDetailModal = true;
        this.selectedTimesheetDetails = {
          ...item,
          billing_start_date: this.billPeriodFrom,
          billing_end_date: this.billPeriodTo,
          flag: this.billNature
        };
      } else {
        this.timesheetSummaryDetailModal = flag;
      }
    },
    deleteTempBill() {
      const payload = {
        billhdrtmpId: this.billHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('fms/deleteTempBill', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.isSuccess = true;
            this.showAlert = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    downloadBillingCompDtl() {
      const downloadPayload = {
        _page: 0,
        _limit: this.createBillData.length,
        bill_hdr_id: this.billHdrId,
        flag: this.billFlag
      };
      this.downloadExcel.downloadData(
        downloadPayload,
        'fms/downloadBillingCompDtl',
        'Billing-Comp-details',
        () => (this.loader = false)
      );
    },
    showHideTaxDetailsModal(flag, item) {
      this.showTaxDetailsModal = flag;
      if (flag) {
        this.rowDetails = item;
        this.billHdrId = this.billDetails.bill_hdr_id;
        this.moduleId = this.billDetails.module_id;
      }
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.billPeriodFrom);
    },
    validatePeriod(glDate) {
      this.module.value && this.legalEntity.value && this.operatingUnit.value
        ? this.getValidatePeriodDate(glDate)
        : alert('Please Select Module, Legal Entity and Operating Unit');
      this.glDate = null;
    },
    getValidatePeriodDate(glDate) {
      const payload = {
        module_id: 22,
        le_id: this.legalEntity.value,
        entity_id: this.operatingUnit.value,
        gl_date: glDate
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getValidatePeriodDate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = `${response.data.message} , GL Date status is ${response.data.data}.`;
            this.validateMsg = response.data.data;
            if (this.validateMsg !== 'OPEN') {
              this.glDate = commonHelper.formattedDate(new Date());
            } else {
              this.glDate = glDate;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setDefaultCurrency() {
      const currency = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.CURRENCY_LIST,
        'INR'
      );
      currency.then(key => {
        this.currency.text = key[0].value_code;
        this.currency.value = key[0].value_set_dtl_id;
      });
    },
    setDefaultSource() {
      const source = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.SOURCE_LIST,
        'MANUAL'
      );
      source.then(key => {
        this.source.text = key[0].value_code.toUpperCase();
        this.source.value = key[0].value_set_dtl_id;
      });
    },
    calculateTotalAmount() {
      const result = this.createBillData.map(elem => elem.amount);
      const total = commonHelper.calculateTotal(result);
      if (total) {
        this.amount = parseFloat(total).toFixed(2);
      }
      this.addEditFmsBill();
    },
    showHideSalesConsumptionDetailModal(flag, item) {
      if (flag && item.sales_agmt_consumption_dtl_id) {
        this.getFmsSalesAgmtConsumptionDetails(item);
      }
      this.showSalesConsumptionDetailModal = flag;
    },
    getFmsSalesAgmtConsumptionDetails(data) {
      const payload = {
        sales_agmt_consumption_dtl_id: data.sales_agmt_consumption_dtl_id,
        bill_comp_id: data.bill_com_id,
        flag: this.billNature
      };
      this.loader = true;
      this.$store
        .dispatch('fms/getFmsSalesAgmtConsumptionDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.salesAgmtConsumptionDtlData = response.data.data;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    deleteFinalBill() {
      if (this.billNature === 'FINAL' && this.billStatus.text !== 'PROCESSED') {
        const payload = {
          billhdrId: this.billHdrId
        };
        this.loader = true;
        this.$store
          .dispatch('fms/deleteFinalBill', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.isSuccess = true;
              this.showAlert = true;
              this.responseMsg = response.data.message;
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
            this.showCreateBillModal = false;
          })
          .catch(err => {
            this.err = err;
            this.loader = false;
          });
      }
    },
    deleteTempBillFinalBill() {
      if (this.billNature === 'PROVISONAL') {
        const confirmation = confirm(
          'Are you sure you want to delete this Bill.'
        );
        if (confirmation) {
          this.deleteTempBill();
        }
      } else {
        const confirmFinalBill = confirm(
          'Are you sure you want to delete this Final Bill.'
        );
        if (confirmFinalBill) {
          this.deleteFinalBill();
        }
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
