export default {
  name: 'TimesheetSummaryDetails',
  props: ['selectedTimesheetSummaryData'],
  data() {
    return {
      loader: false,
      salesAgmtNo: {
        value: null,
        text: null
      },
      project: {
        value: null,
        text: null
      },
      customer: {
        value: null,
        text: null
      },
      customerSite: {
        value: null,
        text: null
      },
      address: null,
      remarks: null,
      status: {
        value: null,
        text: null
      },
      version: null,
      timesheetPeriod: null,
      defaultValue: {
        value: null,
        text: null
      },
      payload: {},
      showUploadExcel: false,
      fmsTimesheetSummaryData: [],
      timesheetSummaryFields: [
        {
          key: 'billing_plan_name',
          label: 'Billing Plan'
        },
        {
          key: 'sa_comp_name',
          label: 'SA Component Name'
        },
        {
          key: 'sa_comp_dtl_id',
          label: 'SA Component Dtl Id'
        },
        {
          key: 'employee_code'
        },
        {
          key: 'designation'
        },
        {
          key: 'employee_name'
        },
        {
          key: 'billing_working_days',
          variant: 'info'
        },
        {
          key: 'billing_monthly_off_days',
          variant: 'info'
        },
        {
          key: 'billing_monthly_holidays',
          variant: 'info'
        },
        {
          key: 'billing_total_paid_days',
          variant: 'info'
        },
        {
          key: 'billing_overtime_days',
          variant: 'info'
        },
        {
          key: 'billing_remarks',
          variant: 'info'
        },
        {
          key: 'shift_name'
        },
        {
          key: 'shift_hour'
        },
        {
          key: 'salary_working_days',
          variant: 'success'
        },
        {
          key: 'salary_monthly_off_days',
          variant: 'success'
        },
        {
          key: 'salary_monthly_holidays',
          variant: 'success'
        },
        {
          key: 'salary_total_paid_days',
          variant: 'success'
        },
        {
          key: 'salary_overtime_days',
          variant: 'success'
        },
        {
          key: 'salary_remarks',
          variant: 'success'
        }
      ],
      timesheetDetailsData: [],
      timesheetDetailsFields: [
        {
          key: 'date'
        },
        {
          key: 'attendance_meaning'
        },
        {
          key: 'shift_name'
        },
        {
          key: 'day_name',
          label: 'Shift Day'
        },
        {
          key: 'regular_hours'
        },
        {
          key: 'overtime_hours'
        }
      ],
      timesheetSummaryData: {
        calender_Name: null,
        calender_weekly_shift_days: null,
        timesheet_year: null,
        timeheet_month: null,
        month_days: null,
        calender_shift_hour: null,
        timesheet_actual_working_days: null,
        timesheet_actual_holidays: null,
        timesheet_overtime_days: null,
        calender_working_days: null,
        calender_holidays: null,
        employee_name: null
      },
      downloadPayload: null,
      isTimesheetSummary: false
    };
  },
  mounted() {
    if (this.selectedTimesheetSummaryData.timesheet_summary == 'Y') {
      this.isTimesheetSummary = true;
      this.getTimesheetSummaryDetailsById(this.selectedTimesheetSummaryData);
    } else{
      this.isTimesheetSummary = false;
      this.getFmsTimesheetSummary(this.selectedTimesheetSummaryData);
    }
  },
  methods: {
    getTimesheetSummaryDetailsById(item) {
      const payload = {
        sales_comp_dtl_id: item.sales_agreement_comp_dtl_id,
        bill_comp_id: item.bill_com_id,
        flag: item.flag
      };
      this.loader = true;
      this.$store
        .dispatch(
          'fmsTimesheet/getTimesheetSummaryDetailsById',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.fmsTimesheetSummaryData = response.data.data;
            this.setTimesheetSummaryHdrDetails(this.fmsTimesheetSummaryData[0]);
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    setTimesheetSummaryHdrDetails(item) {
      this.project = {
        value: item.fms_prj_id,
        text: item.fms_prj_name
      };
      this.salesAgmtNo = {
        value: item.sales_agreement_hdr_id,
        text: item.sales_agreement_num
      };
      this.customer = {
        id: item.customer_id,
        name: item.customer_name
      };
      this.customerSite = {
        id: item.customer_site_id,
        name: item.customer_site_name
      };
      this.address = item.bill_to_address;
      this.remarks = item.remarks;
      this.status = {
        value: item.status,
        text: item.status_meaning
      };
      this.version = item.version;
      this.timesheetPeriod = item.month + ' - ' + item.year;
    },
    getFmsTimesheetSummary(item) {
      const payload = {
        sales_comp_dtl_id: item.sales_agreement_comp_dtl_id,
        bill_comp_id: item.bill_com_id,
        billing_start_date: item.billing_start_date,
        billing_end_date: item.billing_end_date,
        flag: item.flag
      };
      this.downloadPayload = payload;
      this.loader = true;
      this.$store
        .dispatch('fms/getFmsTimesheetSummary', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.timesheetSummaryData = response.data.data;
            this.getFmsTimesheetDetails(item);
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    getFmsTimesheetDetails(item) {
      const payload = {
        sales_comp_dtl_id: item.sales_agreement_comp_dtl_id,
        bill_comp_id: item.bill_com_id,
        billing_start_date: item.billing_start_date,
        billing_end_date: item.billing_end_date,
        flag: item.flag
      };
      this.downloadPayload = payload;
      this.loader = true;
      this.$store
        .dispatch('fms/getFmsTimesheetDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.timesheetDetailsData = response.data.data;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    downloadTimesheetDetails() {
      this.downloadPayload._limit = 31;
      this.downloadExcel.downloadData(
        this.downloadPayload,
        'fms/getFmsTimesheetDetails',
        'timesheet-details',
        () => (this.loader = false),
        this.timesheetDetailsData
      );
    },
  }
};
